import { useState, useMemo, useEffect, FC } from "react";
import {
  PaginationState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import { get } from "lodash";

import Table from "./Table";
import Pagination from "./Pagination";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import callApi from "lib/callAPi";
import WbLoader from "ui/WbLoader";

type BaseGridProps = {
  columns: any;
  gridUrl: string;
  method?: string;
  model?: string;
  filter?: any;
};

const addChekboxColumn = () => {
  const checkboxColumn = {
    id: "select",
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  };

  return checkboxColumn;
};

const BaseGrid: FC<BaseGridProps> = ({ columns, gridUrl, model, filter = [] }) => {
  const [gridData, setGridData] = useState([]);
  const [pageCount, setPageCount] = useState(-1);
  const [searchValue, setSearchValue] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);

  const [rowSelection, setRowSelection] = useState({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );
  const columnHelper = createColumnHelper<any>();
  const dropDownData: { value: any; text: any }[] = [];

  const finalColumns = columns.map((col: any) => {
    let options: any = {};

    dropDownData.push({ value: col.dataIndex, text: col.header });

    options.header = col.header;
    if (col.formatter) {
      options.cell = col.formatter;
    }
    if (col.minSize) {
      options.minSize = col.minSize;
    }
    if (col.maxSize) {
      options.maxSize = col.maxSize;
    }
    options.size = col.size || 200;
    return columnHelper.accessor(col.dataIndex, options);
  });

  const table = useReactTable({
    data: gridData,
    columns: [addChekboxColumn(), ...finalColumns],
    pageCount: pageCount || -1,
    state: {
      pagination,
      sorting,
      rowSelection,
    },
    columnResizeMode: "onChange",
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  filter.push({ column: searchColumn, value: searchValue, operator: "regex" });
  useEffect(() => {
    async function fetchGridData() {
      const sortColumn = get(sorting, "0.id");
      const sortOrder = get(sorting, "0.desc") ? -1 : 1;
      setLoading(true);
      const response: any = await callApi({
        url: gridUrl,
        method: "post",
        body: {
          model,
          filter,
          limit: pageSize,
          page: pageIndex + 1,
          sort: [[sortColumn, sortOrder]],
        },
      });
      setLoading(false);
      const pages = response?.data?.total && Math.ceil(Number(response?.data?.total) / Number(response?.data?.limit));
      setGridData(response?.data?.docs);
      setPageCount(pages);
    }

    fetchGridData();
  }, [pageIndex, pageSize, gridUrl, sorting, model, searchValue]);

  const hadleSelectedData = (e: any) => {
    setSearchColumn(e.target.value);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  return (
    <WbLoader loading={loading}>
      <div className="w-[100%]">
        <div className="flex space-x-4 py-2">
          <select onChange={(e) => hadleSelectedData(e)} className="rounded-lg border border-gray-300 bg-gray-50 p-2">
            <option>Select Column</option>
            {dropDownData?.map((data: any) => (
              <option value={data.value}>{data.text}</option>
            ))}
          </select>
          <input
            type="text"
            className="block w-[200px] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus-visible:ring-0 "
            placeholder={"search"}
            onChange={(e) => handleSearch(e)}
            name={"search"}
          />
        </div>
      </div>
      <div className="realtive overflow-x-auto">
        <Table table={table} />
      </div>
      <Pagination table={table} />
    </WbLoader>
  );
};

export default BaseGrid;
