import moment from "moment";

import BaseGrid from "ui/BaseGrid";

const CSEnquiryList = () => {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "name",
      header: "Name",
      isQuickFilter: true,
    },
    {
      dataIndex: "email",
      header: "Email",
      isQuickFilter: true,
    },
    {
      dataIndex: "phoneNumber",
      header: "Phone Number",
    },
    {
      dataIndex: "subject",
      header: "Subject",
    },
    {
      dataIndex: "message",
      header: "Message",
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
  ];

  return (
    <>
      <BaseGrid
        gridUrl="/api/cs-enquiry/list"
        method="POST"
        columns={columns}
      />
    </>
  );
};

export default CSEnquiryList;
