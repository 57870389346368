import { useAuth0 } from "@auth0/auth0-react";
import { FaCheck } from "react-icons/fa";

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  return (
    <section className="h-full">
      <div className="flex flex-row">
        <div className="min-h-screen w-[50%] rounded-r-xl bg-gray-800 p-20 text-white shadow-lg">
          <img src="login.svg" className="mx-auto w-[70%] text-orange-500" alt="Sample" />
          <p className="my-5 text-2xl leading-loose tracking-wide text-gray-300">
            <span className="text-5xl">"</span>Wyzebulb pulls every platform that customers use to contact business into one place. Wyzebulb cuts down
            the time it takes for sales and marketing agents to collect information and provides self-service to the customers, and increase ROI.
            <span className="text-5xl">"</span>
          </p>
        </div>
        <div className="mt-20 flex w-[50%] flex-col items-center">
          <div>
            <img src="wyzebulb-1.png" alt="logo" className="h-[80%] w-[80%] object-contain" />
          </div>
          <div className=" w-[60%] space-y-5 rounded-lg shadow-sm">
            <div className="p-4 text-center">
              <h1 className="text-4xl font-medium leading-relaxed tracking-normal text-gray-700">Login to Wyzebulb Admin</h1>
              <h6 className="text-lg text-gray-600">Manage & Support your customers like a Pro!</h6>
            </div>

            <div className="mx-auto max-w-[70%] space-y-2 p-4 text-lg font-normal leading-relaxed">
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Customers</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Flows</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Tasks</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Configuration</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Settings</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Support</span>
              </p>
              <p className="space-x-2">
                <FaCheck className="inline text-orange-500" />
                <span className="text-gray-700">Manage Monitoring</span>
              </p>
            </div>
            <div className="mx-auto max-w-[90%] p-4">
              <button className="mb-0 mr-4 w-[100%] rounded-lg border bg-orange-600 px-4 py-3 text-lg text-white" onClick={() => loginWithRedirect()}>
                Sign in with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
