import { groupBy, flattenDeep, startCase, each } from "lodash";

interface ITaskData {
  _id: string;
  flowId: string;
  flowName: string;
  flowFormattedName: string;
  customerEmail: string;
  status: string;
  date: string;
  error: string;
}

const fallBackChartLabel = "Data not available!";
const fallBackChartCateogry = [fallBackChartLabel];
const fallBackChartData = [
  {
    name: fallBackChartLabel,
    Executed: 0,
    Filtered: 0,
    Errored: 0,
    Running: 0,
  },
];

const processTaskCountByStatus = (tasks: ITaskData[]) => {
  const executed = tasks.reduce((sum: number, t: ITaskData) => {
    if (t.status === "executed" || t.status === "warning") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  const filtered = tasks.reduce((sum: number, t: ITaskData) => {
    if (t.status === "filtered") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  const errored = tasks.reduce((sum: number, t: ITaskData) => {
    if (t.status === "error") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);
  const running = tasks.reduce((sum: number, t: ITaskData) => {
    if (t.status === "running") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  return {
    Executed: executed,
    Filtered: filtered,
    Errored: errored,
    Running: running,
  };
};

/*  handling the date range state for the Bar Chart */
const getTasksFilteredByRange = (groupedByTasks: any, dateRange: string[]) => {
  const tasksToReturn: any = {};

  each(groupedByTasks, (tasks: ITaskData[], name: string) => {
    const filteredTasks = tasks.filter((t: ITaskData) => dateRange.includes(t.date));
    filteredTasks.length > 0 && (tasksToReturn[name] = filteredTasks);
  });

  return tasksToReturn;
};

export const getAppSummaryData = (tasksChartRawData: ITaskData[], dateRange: string[]) => {
  const data = flattenDeep(
    tasksChartRawData.map((t: ITaskData) => {
      const apps = t.flowFormattedName?.split("_to_");
      return [
        { ...t, app: apps[0] },
        { ...t, app: apps[1] },
      ];
    }),
  );

  const groupedByApp = groupBy(data, "app");
  const appBasedTasks = getTasksFilteredByRange(groupedByApp, dateRange);

  const formattedData = Object.keys(appBasedTasks).map((d: string) => {
    const value = appBasedTasks[d];
    const tasksCountByStatus = processTaskCountByStatus(value);

    return {
      name: startCase(d),
      ...tasksCountByStatus,
    };
  });

  return formattedData.length > 0 ? formattedData : fallBackChartData;
};

export const getFlowSummaryData = (tasksChartRawData: ITaskData[], dateRange: string[]) => {
  const groupedByFlow = groupBy(tasksChartRawData, "flowFormattedName");
  const flowBasedTasks = getTasksFilteredByRange(groupedByFlow, dateRange);

  const formattedData = Object.keys(flowBasedTasks).map((d: string) => {
    const value = flowBasedTasks[d];
    const tasksCountByStatus = processTaskCountByStatus(value);

    return {
      name: startCase(d),
      ...tasksCountByStatus,
    };
  });

  return formattedData.length > 0 ? formattedData : fallBackChartData;
};

export const getErrorSummaryData = (tasksChartRawData: ITaskData[], dateRange: string[]) => {
  const filteredErrorTasks = tasksChartRawData.filter((t: any) => t.error !== "");
  const groupedByErr = groupBy(filteredErrorTasks, "error");
  const errorBasedTasks = getTasksFilteredByRange(groupedByErr, dateRange);

  const formattedData = Object.keys(errorBasedTasks).map((d: any) => {
    const value = errorBasedTasks[d];
    const total = value.length;

    let collectedData: any = {};
    Object.keys(errorBasedTasks).forEach((k: any) => {
      return (collectedData[k] = 0);
    });

    return {
      ...collectedData,
      name: d,
      [d]: total,
    };
  });
  const categories = Object.keys(errorBasedTasks);

  return {
    categories: categories.length > 0 ? categories : fallBackChartCateogry,
    formattedData: formattedData.length > 0 ? formattedData : fallBackChartData,
  };
};

export const getStatusSummaryData = (tasksChartRawData: ITaskData[], dateRange: string[]) => {
  const groupByDate = groupBy(tasksChartRawData, "date");

  const dateBasedTasks: any = {};
  dateRange.forEach((d: string) => (dateBasedTasks[d] = groupByDate[d] || []));

  const formattedData = Object.keys(dateBasedTasks).map((d: any) => {
    const value = dateBasedTasks[d];
    const tasksCountByStatus = processTaskCountByStatus(value);

    return {
      name: d,
      ...tasksCountByStatus,
    };
  });

  return formattedData.length > 0 ? formattedData : fallBackChartData;
};

export const getCustomerSummaryData = (tasksChartRawData: ITaskData[], dateRange: string[]) => {
  const groupByCustomer = groupBy(tasksChartRawData, "customerEmail");

  const formattedData = Object.keys(groupByCustomer).map((email: any) => {
    const value = groupByCustomer[email];
    const tasksCountByCustomerEmail = processTaskCountByStatus(value);

    return {
      name: email,
      ...tasksCountByCustomerEmail,
    };
  });

  return formattedData.length > 0 ? formattedData : fallBackChartData;
};
