import { Routes, Route } from "react-router-dom";

import FlowsList from "./FlowsList";
import Details from "./Details";

function FlowRouter() {
	return (
		<Routes>
			<Route path="" element={<FlowsList />} />
			<Route path="details/:flowName/:flowId" element={<Details />} />
		</Routes>
	);
}

export default FlowRouter;
