import { startCase } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";

import WbBadge from "ui/WbBadge";
import BaseGrid from "ui/BaseGrid";

export default function CrmLeadsDataList() {
    // const formatters = {
    //     dateFormatter: (cell: any) => moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A"),
    //     startCaseFormatter: (cell: any) => startCase(cell.getValue()),
    //     fPDIdFormatter: (cell: any) => <Link to={`/FlowProgressDetails/${cell.getValue()}`}> {cell}</Link>,
    //     statusFormatter: (cell: any) => <WbBadge color="info">{startCase(cell.getValue())}</WbBadge>,
    // };

    const columns = [
        {
            dataIndex: "createdAt",
            header: "Date",
            // formatter: formatters.dateFormatter,
        },
        {
            dataIndex: "leadFirstName",
            header: "First Name",
            // formatter: formatters.startCaseFormatter,
        },
        {
            dataIndex: "leadLastName",
            header: "Last Name",
            // formatter: formatters.startCaseFormatter,
        },
        {
            dataIndex: "leadPhone",
            header: "Phone No",
        },
        {
            dataIndex: "leadEmail",
            header: "Email",
        },
        {
            dataIndex: "status",
            header: "Status",
            // formatter: formatters.statusFormatter,
            searchable: true,
        },
        {
            dataIndex: "source",
            header: "Source",
            // formatter: formatters.startCaseFormatter,
            searchable: true,
        },
        {
            dataIndex: "email",
            header: "Belongs To",
            searchable: true,
        },
        {
            dataIndex: "flowProgressId",
            header: "Flow Progress Id",
            // formatter: formatters.fPDIdFormatter,
        },

        {
            dataIndex: "comment",
            header: "Comment Text",
            // formatter: formatters.fPDIdFormatter,
        },
        {
            dataIndex: "commentId",
            header: "Comment ID",
            // formatter: formatters.fPDIdFormatter,
        },
        {
            dataIndex: "message",
            header: "Message",
            // formatter: formatters.fPDIdFormatter,
        },
        {
          dataIndex: "messageId",
          header: "Message ID",
          // formatter: formatters.fPDIdFormatter,
      },
      {
        dataIndex: "commentedBy",
        header: "Commented By",
        // formatter: formatters.fPDIdFormatter,
    },
    {
      dataIndex: "pageId",
      header: "Page ID",
      // formatter: formatters.fPDIdFormatter,
  },
    ];

    return <BaseGrid gridUrl="/api/crmleads/list" method="post" columns={columns} />;
}
