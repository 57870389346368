import classNames from "classnames";

const theme = {
    base: "flex rounded-lg border-2 border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-700 dark:text-white",
    children: "flex h-full flex-col justify-center gap-4 p-6",
    horizontal: {
        off: "flex-col",
        on: "flex-col md:max-w-xl md:flex-row",
    },
    href: "hover:bg-gray-100 dark:hover:bg-gray-700",
    img: {
        base: "",
        horizontal: {
            off: "rounded-t-lg",
            on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
        },
    },
};

export default function WbCard(props) {
    const { children, className, horizontal, href, imgAlt, imgSrc, ...restProps } = props;
    const Component = typeof href === "undefined" ? "div" : "a";

    return (
        <Component
            className={classNames(theme.base, theme.horizontal[horizontal ? "on" : "off"], href && theme.href, className)}
            data-testid="wb-card"
            href={href}
            {...restProps}
        >
            {imgSrc && (
                <img alt={imgAlt ?? ""} className={classNames(theme.img.base, theme.img.horizontal[horizontal ? "on" : "off"])} src={imgSrc} />
            )}
            <div className={theme.children}>{children}</div>
        </Component>
    );
}
