import { Divider } from "@tremor/react";
import UserSummary from "./UserSummary";
import TaskSummary from "./TasksSummary";

import AppTabBuilder from "components/AppTabBuilder";

const Dashboard = () => {
  return (
    <div className="w-full">
      <AppTabBuilder
        tabs={[
          {
            name: "Task",
            content: <TaskSummary />,
          },
          {
            name: "Test Flow",
            content: (
              <TaskSummary
                query={{ "stageData.0.dataForStageAndQueue.dataIn.eventData.executionType": "testFlow" }}
                customerEmail={"dev.demo@wyzebulb.com"}
              />
            ),
          },
        ]}
      />
      <Divider />
      <UserSummary />
    </div>
  );
};

export default Dashboard;
