import { find, get, groupBy, isEmpty } from "lodash";

export const templateConfig: any = {
    google_sheets: {
        baseUrl: "https://docs.google.com/spreadsheets/{firstParam}",
        fieldKey: "instagram_account_name",
    },
    instagram: {
        baseUrl: "https://www.instagram.com",
        apiDataSlug: "instagram_account_name",
        fieldSlug: "displayText",
    },
};

const modifiedUrl: any = (slug: string, flowData: any) => {
    if (!isEmpty(flowData)) {
        let url;

        // const groupByStages = groupBy(flowData?.stages, "stageType");
        // const actionObj = get(groupByStages, "action.0.stageItems.setupActionTemplate.apiData");
        // const triggerObj = get(
        //     groupByStages,
        //     "trigger.0.stageItems.setupEventOptions.selectedDynamicIncomingDataPrerequisites"
        // );
        // console.log("triggerObj", triggerObj);

        // const { baseUrl, apiDataSlug, fieldSlug } = templateConfig[slug];

        // const requiredParamObj = find(actionObj, { slug: apiDataSlug });
        // const firstParam = get(requiredParamObj, `jsonDetails.0.valueDetails.0.${fieldSlug}`, "");

        // const mainUrl = baseUrl.replace("{firstParam}", firstParam);

        switch (slug) {
            case "instagram":
                let username = get(
                    flowData,
                    "stages[1].stageItems.setupActionTemplate.dataFromPreviousStages[0].stageData[0].testValue",
                    ""
                );
                url = `${templateConfig.instagram.baseUrl}/${username}`;
                break;
            case "google_sheets":
                url = `${templateConfig.google_sheets}/${flowData}`;
                break;
        }
        return url;
    }
};

export default modifiedUrl;
