import moment from "moment";
import { Link } from "react-router-dom";

function UsersSummary() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell).format("DD-MM-YYYY hh:mm:ss A");
    },
    subIdFormatter: (cell: any, row: any) => {
      const email = cell;
      return <Link to={`/UserSummaryDetails/${email}`}> {email}</Link>;
    },
    statusFormatter: (cell: any) => {
      if (cell === "FREE") {
        return <div>FREE</div>;
      } else if (cell === "PAID") {
        return <div>PAID</div>;
      }
    },
  };

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataField: "email",
      text: "Email Id",
      isQuickFilter: true,
      formatter: columnFormatter.subIdFormatter,
    },
    {
      dataField: "userType",
      text: "User Type",
      isQuickFilter: true,
      formatter: columnFormatter.statusFormatter,
    },
  ];

  return (
    <>
      {/*<WbGrid url="/api/analytics/list" method="POST" columns={columns}>
      User Summary
  </WbGrid>*/}
    </>
  );
}

export default UsersSummary;
