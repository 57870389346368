import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import WbMenuButton from "./WbMenuButton";
import WbMenuItem from "./WbMenuItem";

export default function WbMenu(props) {
  const { menuItems, ...restProps } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <WbMenuButton {...restProps} />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-1 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1 p-1 dark:bg-gray-800 dark:ring-white">
          {menuItems.map(({ text, icon, onClick }, idx) => (
            <WbMenuItem text={text} icon={icon} onClick={onClick} key={idx} />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
