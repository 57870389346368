import { Dropdown, DropdownItem } from "@tremor/react";

interface IDropdownItem {
  value: string;
  label: string;
  icon?: React.ElementType;
}

interface IDropdownProps {
  title?: string;
  items: IDropdownItem[];
  onChange?: (value: string) => void;
}

export default function DashboardDropdown({
  items,
  title,
  onChange,
}: IDropdownProps) {
  return (
    <Dropdown
      placeholder={title}
      onValueChange={onChange}
      defaultValue={items[0].value}
      maxWidth="max-w-fit"
    >
      {items.map((item, index) => (
        <DropdownItem
          key={index}
          value={item.value}
          text={item.label}
          icon={item.icon}
        />
      ))}
    </Dropdown>
  );
}
