import { useState } from "react";
import { lowerCase, kebabCase } from "lodash";

interface TabBuilderProps {
  tabs: Tabs[];
}

interface Tabs {
  name: string;
  content: JSX.Element;
}

const AppTabBuilder = (props: TabBuilderProps) => {
  const [activeKey, setActiveKey] = useState(0);

  const toggleTab = (tab: number) => {
    if (activeKey !== tab) {
      setActiveKey(tab);
    }
  };
  return (
    <>
      <div role="tablist" className="ml-2 flex space-x-2 border-b">
        {Object.keys(props.tabs)?.map((keyName: any, index: number) => (
          <div
            className={`p-2.5 text-sm font-medium leading-5 focus:outline-none
              ${activeKey === index ? "border-b-2 border-orange-600 text-orange-600" : "text-gray-500 hover:border-b-2"}`}
            onClick={() => toggleTab(index)}
          >
            <span>{props.tabs[keyName].name}</span>
          </div>
        ))}
      </div>
      <div>
        {Object.keys(props.tabs)?.map((keyName: any, index: number) => (
          <>
            {activeKey === index ? (
              <div role="tabpanel" aria-labelledby={`${kebabCase(lowerCase(props.tabs[keyName].name))}-tab`}>
                {props.tabs[keyName].content}
              </div>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default AppTabBuilder;
