import { FaUsers, FaUserLock, FaUniversalAccess } from "react-icons/fa";

import CardLayout from "ui/CardLayout";
const configurationCards = [
    {
        title: "Users",
        icon: <FaUsers size={32} />,
        href: "users",
    },
    {
        title: "Billing Plans",
        icon: <FaUsers size={32} />,
        href: "billing-plans",
    },
    {
        title: "Email Templates",
        icon: <FaUniversalAccess size={32} />,
        href: "email-templates",
    },
    {
        title: "Social Accounts",
        icon: <FaUniversalAccess size={32} />,
        href: "social-accounts",
    },
];

const monitoringCards = [
    {
        title: "Email Logs",
        icon: <FaUserLock size={32} />,
        href: "email-logs",
    },
    {
        title: "Usage Records",
        icon: <FaUniversalAccess size={32} />,
        href: "usage-records",
    },
    {
        title: "Automation Providers",
        icon: <FaUniversalAccess size={32} />,
        href: "automation-providers",
    },
    {
        title: "Webhook Data",
        icon: <FaUserLock size={32} />,
        href: "webhook-data",
    },
];

const supportCards = [
    {
        title: "Wyzebulb Enquiry",
        icon: <FaUniversalAccess size={32} />,
        href: "wb-Enquiry",
    },
    {
        title: "CS Enquiry",
        icon: <FaUniversalAccess size={32} />,
        href: "cs-Enquiry",
    },
];

const extras = [
    {
        title: "Logs",
        icon: <FaUniversalAccess size={32} />,
        href: "logs",
    },
    {
        title: "User Summary",
        icon: <FaUniversalAccess size={32} />,
        href: "user-summary",
    },
    {
        title: "Flow Summary",
        icon: <FaUniversalAccess size={32} />,
        href: "flow-summary",
    },
    {
        title: "Flow Template",
        icon: <FaUniversalAccess size={32} />,
        href: "flow-template",
    },
    {
        title: "CRM Leads Data",
        icon: <FaUniversalAccess size={32} />,
        href: "crm-leads-data",
    },
];

function SettingsLandingPage() {
    return (
        <div>
            <h1 className="text-xl my-5">Manage Configuration</h1>
            <CardLayout cards={configurationCards} />
            <h1 className="text-xl my-5">Manage Monitoring</h1>
            <CardLayout cards={monitoringCards} />
            <h1 className="text-xl my-5">Manage Support</h1>
            <CardLayout cards={supportCards} />
            <h1 className="text-xl my-5">Extras</h1>
            <CardLayout cards={extras} />
        </div>
    );
}

export default SettingsLandingPage;
