import { Card, BarChart, Flex } from "@tremor/react";
import callApi from "lib/callAPi";
import { startCase } from "lodash";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";

import { ChartTitleBlock, ChartToggleOptions } from "./common/ChartModules";

import DashboardDropdown from "./common/Dropdown";
import { getAppSummaryData, getCustomerSummaryData, getErrorSummaryData, getFlowSummaryData, getStatusSummaryData } from "./processChartData";

/* Creating an Array of Dates from Today to Month Back in MMM-DD format */
export const getDateArrByType = (type: any) => {
  let date = [];
  const endDate = moment().subtract(1, type || "month"); // year, month, week, day

  for (let m = moment(endDate); m.isBefore(moment()); m.add(1, "days")) {
    date.push(m.format("MMM-DD"));
  }

  return date;
};
export default function TaskDashboard(props: any) {
  const { query = {}, customerEmail = "" } = props;

  const [periodType, setPeriodType] = useState("day");
  const [selectedGroupBy, setSelectedGroupBy] = useState<string>("status");
  const [chartData, setChartData] = useState<any>([]);
  const [chartCategories, setChartCategories] = useState(["Executed", "Filtered", "Errored", "Running"]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tasksData, setTasksData] = useState<any>({
    tasks: [],
    tasksCount: 0,
    tasksCountByDate: [],
    taskDataForChart: [],
  });

  const fetchTasks = useCallback(async () => {
    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment(endDate).subtract(1, "months").toISOString().split("T")[0];
    try {
      const tasksReqBody = {
        url: `/api/dashboard/tasks/analytics`,
        method: "POST",
        body: {
          status: ["executed", "warning", "error", "running"],
          projection: {
            flowId: 1,
            updatedAt: 1,
            createdAt: 1,
            flowProgressStatus: 1,
            stageData: 1,
          },
          customerEmail,
          startDate,
          query,
          endDate,
        },
      };
      const response: any = await callApi(tasksReqBody);
      console.log("response: ", response);

      setTasksData(response.data?.tasksData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  // Handle Grouped By Dropdown
  const handleGroupBy = (type: string) => {
    setSelectedGroupBy(type);
    if (type !== "error") {
      setChartCategories(["Executed", "Filtered", "Errored", "Running"]);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    let chartData: any = [];
    const dateArrByType = getDateArrByType(periodType);
    const tasksChartRawData = tasksData.taskDataForChart;

    // Generating the Chart Data based on the Selected Type
    if (selectedGroupBy === "error") {
      const { categories, formattedData } = getErrorSummaryData(tasksChartRawData, dateArrByType);

      chartData = formattedData;
      setChartCategories(categories);
    } else if (selectedGroupBy === "appStatus") {
      chartData = getAppSummaryData(tasksChartRawData, dateArrByType);
    } else if (selectedGroupBy === "flows") {
      chartData = getFlowSummaryData(tasksChartRawData, dateArrByType);
    } else if (selectedGroupBy === "customerEmail") {
      chartData = getCustomerSummaryData(tasksChartRawData, dateArrByType);
    } else {
      chartData = getStatusSummaryData(tasksChartRawData, dateArrByType);
    }

    setChartData(chartData);
  }, [periodType, selectedGroupBy, tasksData.taskDataForChart]);

  const dropdownItems = ["status", "flows", "error", "appStatus", "customerEmail"].map((item) => ({
    label: startCase(item),
    value: item,
  }));

  return (
    <div className="p-5">
      <Card>
        <Flex>
          <ChartTitleBlock title={startCase(selectedGroupBy)} />
          <ChartToggleOptions color={"orange"} items={["month", "week", "day"]} defaultIndex={2} handleSelect={setPeriodType} />
          <span className="mx-1" />
          <DashboardDropdown title="Group by" items={dropdownItems} onChange={handleGroupBy} />
        </Flex>
        <BarChart data={chartData} dataKey="name" categories={chartCategories} stack={true} marginTop="mt-6" yAxisWidth="w-8" />
      </Card>
    </div>
  );
}
