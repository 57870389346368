import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, isEmpty, startCase } from "lodash";
import { FaSignOutAlt, FaUser } from "react-icons/fa";

import WbMenu from "ui/WbMenu";
import { useState, useEffect } from "react";

export default function TopNavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const fullPathName = location?.pathname;
  const urlSegments = fullPathName?.split("/");
  const heading = urlSegments[urlSegments.length - 1];
  const regExp = /[0-9]/.test(heading);
  const { user, logout } = useAuth0();
  const [image, setImage] = useState("");

  const path = window.location.pathname;

  useEffect(() => {
    setImage("");
  }, [path]);

  const setDefaultImage = (e: any) => {
    setImage("favicon_base");
  };

  const menuItems = [
    {
      text: "Account",
      icon: <FaUser size={14} />,
      onClick: () => navigate("account"),
    },
    {
      text: "Sign out",
      icon: <FaSignOutAlt size={14} />,
      onClick: () => logoutWithRedirect(),
    },
  ];

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <>
      <div className="sticky top-0 left-0 flex h-16 flex-row justify-between border-b bg-gray-50 py-5 px-5">
        <div className="flex">
          {path === `/Details/${heading}` ? (
            <img
              className="mr-2 h-8 w-8 object-contain"
              src={
                isEmpty(image)
                  ? `https://main.wyzebulb.com/assets/img/integrations/hex_${heading}.png`
                  : `https://main.wyzebulb.com/assets/img/logos/${image}.png`
              }
              alt={`integration_${heading}`}
              onError={setDefaultImage}
            ></img>
          ) : (
            ""
          )}

          <h2 className="text-xl font-medium text-gray-800">
            {regExp ? heading : startCase(heading)}
          </h2>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <h4>{user && get(user, "name")}</h4>
          <WbMenu menuItems={menuItems}>
            <img
              src={user && get(user, "picture")}
              alt="Profile"
              className="h-8 w-8 rounded-2xl"
            />
          </WbMenu>
        </div>
      </div>
    </>
  );
}
