import { FaBan } from "react-icons/fa";

import Login from "./Login";

const AlertUser = () => {
  return (
    <div className=" absolute left-[50%] z-40 w-[50%] rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <FaBan className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Not a valid domain
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              <li>
                Your domain is not authenticated to login to this application.
                Please contact support.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function UnAuthLayout(props: any) {
  const { notAllowed } = props;

  return (
    <>
      {notAllowed && <AlertUser />}
      <div className="w-full">
        <Login />
      </div>
    </>
  );
}
