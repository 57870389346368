import { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { Drawer, Button } from "antd";
import moment from "moment";
// import ReactJson from "react-json-view";

import BaseGrid from "ui/BaseGrid";

const WebhookDataList = () => {
  const [open, setOpen] = useState(false);
  const [webhooksData, setWebhooksData] = useState({});
  const onClose = () => {
    setOpen(false);
  };

  const columnFormatter = {
    idFormatter: (cell: any, row: any) => {
      const id = cell.getValue();
      const handleOnClick = () => {
        setOpen(true);
        setWebhooksData(row);
      };
      return (
        <Link onClick={handleOnClick} to={""}>
          {id}
        </Link>
      );
    },
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },
    flowIdFormatter: (cell: any, row: any) => {
      const flowId = cell.getValue();
      return <Link to={`/FlowProgressDetails/${flowId}`}> {flowId}</Link>;
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "_id",
      header: "Id",
      formatter: columnFormatter.idFormatter,
      isQuickFilter: true,
    },
    {
      dataIndex: "flowId",
      header: "Flow Id",
      formatter: columnFormatter.flowIdFormatter,
    },
  ];

  return (
    <>
      <BaseGrid
        gridUrl="/api/webhookData/list"
        method="POST"
        columns={columns}
      />
      <Drawer
        title="Webhook Data"
        placement="right"
        zIndex={1029}
        width="50%"
        open={open}
        onClose={onClose}
      >
        {/*<ReactJson src={!isEmpty(webhooksData) ? webhooksData : {}} />*/}
        <Button
          type="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {" "}
          Close{" "}
        </Button>
      </Drawer>
    </>
  );
};

export default WebhookDataList;
