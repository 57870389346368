import { Menu } from "@headlessui/react";

export default function WbMenuItem({ text, icon, onClick }) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            active
              ? "bg-orange-600 text-white"
              : "text-gray-900 dark:text-white"
          } group flex w-full items-center rounded-md px-2 py-2 text-sm space-x-2`}
          onClick={onClick}
        >
          {icon}
          <span>{text}</span>
        </button>
      )}
    </Menu.Item>
  );
}
