import moment from "moment";
import { startCase } from "lodash";

import CreateEmail from "./CreateTemplate";
import BaseGrid from "ui/BaseGrid";

function EmailTemplates() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },

    textFormatter: (cell: any) => {
      return startCase(cell.getValue());
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "_id",
      header: "Id",
      size: "1000px",
    },
    {
      dataIndex: "templateName",
      header: "Template Name",
      size: "1000px",
      formatter: columnFormatter.textFormatter,
      isQuickFilter: true,
    },
    {
      dataIndex: "fromEmail",
      header: "From Email",
      isQuickFilter: true,
    },
    {
      dataIndex: "subject",
      header: "Subject",
      isQuickFilter: true,
    },
    {
      dataIndex: "body",
      header: "Body",
    },
    {
      dataIndex: "createdBy",
      header: "Created By",
    },
    {
      dataIndex: "updatedBy",
      header: "Updated By",
    },
  ];

  return (
    <div>
      {/*<CreateEmail />*/}
      <BaseGrid
        gridUrl="/api/emailtemplate/list"
        method="POST"
        columns={columns}
      />
    </div>
  );
}

export default EmailTemplates;
