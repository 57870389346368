import Row from "antd/lib/row";
import callApi from "lib/callAPi";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaSync } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { WbCard } from "ui/WbCard";

const SocialAccountList = () => {
    const [selectedAccList, setSelectedAccList] = useState<any>([]);

    const [refreshOAuthWindow, setRefreshOAuthWindow] = useState(false);
    const [search, setSearch] = useState(selectedAccList);

    const googleapp = selectedAccList.some(({ accountType, isActive }: any) => accountType === "google" && isActive === true);
    const facebookapp = selectedAccList.some(({ accountType, isActive }: any) => accountType === "facebook" && isActive === true);
    const linkedinapp = selectedAccList.some(({ accountType, isActive }: any) => accountType === "linkedin" && isActive === true);

    const handleDisconnect = (accountType: string) => {
        setSelectedAccList(selectedAccList.filter((a: any) => a.accountType !== accountType));
        getAccountStatus(accountType);
    };

    useEffect(() => {
        getDetails();
    }, [refreshOAuthWindow]);

    useEffect(() => {
        setSearch(selectedAccList);
    }, [selectedAccList]);

    const getDetails = async () => {
        const url = `/api/socialaccounts/getDetails`;

        const payload = {
            url,
            method: "POST",
            body: { accountType: "accountType" },
        };
        const accountsResponse = await callApi(payload);
        const accounts = accountsResponse.data.accounts || [];
        setSelectedAccList(accounts);
    };

    const getAccountStatus = async (accountType: any) => {
        const url = `/api/socialaccounts/disconnectAccount`;
        const payload = {
            url,
            method: "POST",
            body: { accountType: accountType },
        };
        const accountsResponse = await callApi(payload);
        const response = accountsResponse.data.accounts.accountType;
        return response;
    };
    const handleOAuthWindow = (provider: string) => {
        setRefreshOAuthWindow(false);
        openOAuthWindow(provider);
    };

    const openOAuthWindow = (provider: string) => {
        const x = window.screen.width / 2 - 600 / 2;
        const y = window.screen.height / 2 - 600 / 2;

        const oauthWindow = window.open(`http://localhost:5000/auth/${provider}`, "popup", `height=600,width=600,left=${x},top=${y}`);

        !refreshOAuthWindow &&
            setInterval(() => {
                if (oauthWindow?.location?.pathname?.includes("settings")) {
                    oauthWindow.close();
                    setRefreshOAuthWindow(true);
                }
            }, 1000);
    };

    const handleSearch = (e: React.FormEvent<EventTarget>): void => {
        const target = e.target as HTMLInputElement;
        const searchedValue = target.value;
        const newsearch = selectedAccList?.filter(
            ({ email, accountType }: any) => email?.toLowerCase().includes(searchedValue) || accountType.toLowerCase().includes(searchedValue),
        );

        setSearch(newsearch);
    };
    function refreshPage() {
        window.location.reload();
    }
    return (
        <>
            {/* Social Accounts Card Code */}
            <div className="grid grid-cols-3 gap-6 cursor-pointer justify-between">
                <div className=" rounded overflow-hidden shadow-lg mt-4 w-[80%] p-5 space-y-3" onClick={() => handleOAuthWindow("google")}>
                    <div className="flex justify-center">
                        <img className="w-[20%]" src="/google.png" alt="" />
                    </div>
                    <div className="font-bold text-xl  text-center">Google</div>
                    <div className="">
                        {googleapp ? (
                            <div className="text-green-500 flex justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ">
                                    <path
                                        fillRule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-32">
                                <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                    </div>
                </div>
                <div className=" rounded overflow-hidden shadow-lg mt-4 w-[80%] p-5 space-y-3" onClick={() => handleOAuthWindow("facebook")}>
                    <div className="flex justify-center">
                        <img className="w-[20%]" src="/facebook.png" alt="" />
                    </div>
                    <div className="font-bold text-xl  text-center">Facebook</div>
                    <div className="">
                        {facebookapp ? (
                            <div className="text-green-500 flex justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ">
                                    <path
                                        fillRule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-32">
                                <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                    </div>
                </div>
                <div className=" rounded overflow-hidden shadow-lg mt-4 w-[80%] p-5 space-y-3" onClick={() => handleOAuthWindow("linkedin")}>
                    <div className="flex justify-center">
                        <img className="w-[20%]" src="/linkedin.png" alt="" />
                    </div>
                    <div className="font-bold text-xl  text-center">Linkedin</div>
                    <div className="">
                        {linkedinapp ? (
                            <div className="text-green-500 flex justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ">
                                    <path
                                        fillRule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-32">
                                <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                    </div>
                </div>
            </div>

            {/* Search Bar Code */}

            <div className="pt-9 flex  ">
                <Row className="text-2xl font-bold">List</Row>

                <div className="flex items-center justify-end ml-auto">
                    <div>
                        <button onClick={refreshPage}>
                            <FaSync size={16} className="text-blue-500 mr-5 h-5 w-5 mt-2" />
                        </button>
                    </div>
                    <div className="flex border border-purple-200 rounded">
                        <input
                            type="text"
                            className="block w-full px-4 py-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40 "
                            placeholder="Search..."
                            onChange={(e: React.FormEvent<EventTarget>): void => handleSearch(e)}
                        />
                    </div>
                </div>
            </div>

            {/* List Card Code */}

            {!isEmpty(search) && true ? (
                search.map((acc: any, idx: number) => {
                    const email = acc?.email;
                    const accountType = acc.accountType;
                    const addedDate = acc?.credentials.createdAt;
                    return (
                        <div className="pt-5 " key={idx}>
                            <WbCard className="hover:bg-orange-50 dark:hover:bg-slate-400 hover:cursor-pointer w-[100%]">
                                <div className="flex ">
                                    <div className="flex flex-row items-center space-x-2">
                                        <img
                                            className="w-10 h-10 object-contain border p-2 rounded-full bg-gray-50"
                                            src={`/${accountType}.png`}
                                            alt="triggerApp"
                                        />
                                    </div>
                                    <div className="ml-4 mt-2 flex flex-col">
                                        <span className="text-xl font-semibold">{email}</span>
                                        <span className="text-sm text-slate-600 dark:text-slate-400">
                                            Connected-on {moment(addedDate).fromNow() || ""}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-end ml-auto">
                                        <FaTimesCircle size={26} className="text-red-500 " onClick={() => handleDisconnect(accountType)} />
                                    </div>
                                </div>
                            </WbCard>
                        </div>
                    );
                })
            ) : (
                <div>
                    <WbCard className="hover:bg-orange-50 dark:hover:bg-slate-400 hover:cursor-pointer w-[100%]">
                        <div className="flex">
                            <h1>No Account Found</h1>
                        </div>
                    </WbCard>
                </div>
            )}
        </>
    );
};

export default SocialAccountList;
