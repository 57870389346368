import { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";

import BaseGrid from "ui/BaseGrid";

const BillingPlanList = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [open, setOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<{
    price: number;
    isActive: string;
    features: any[];
  }>();
  const showModal = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSave = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRefreshGrid(false);
  }, [refreshGrid]);

  const columnFormatter = {
    editButton: (cell: any, row: any) => {
      const showEditModal = (cell: any, row: any) => {
        setOpen(true);
        setEditModalData(row);
      };
      return <button onClick={(cell) => showEditModal(cell, row)}>Edit</button>;
    },

    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MMM-YYYY hh:mm:ss A");
    },
    vendorFormater: (cell: any) => {
      const vendorPlanId = cell.getValue();
      return (
        <Link to={`/BillingPlanDetails/${vendorPlanId}`}> {vendorPlanId}</Link>
      );
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "vendorPlanId",
      formatter: columnFormatter.vendorFormater,
      header: "Vendor Plan id",
    },
    {
      dataIndex: "displayText",
      header: "Display Text",
      isQuickFilter: true,
    },
    {
      dataIndex: "actions",
      header: "Actions",
      formatter: columnFormatter.editButton,
    },
    {
      dataIndex: "vendor",
      header: "Vendor",
      isQuickFilter: true,
    },
    {
      dataIndex: "features",
      header: "Features",
      isQuickFilter: true,
    },
    {
      dataIndex: "isActive",
      header: "Is Active",
    },
    {
      dataIndex: "vendorPlanName",
      header: "Vendor Plan Name",
    },
    {
      dataIndex: "taskLimits.min",
      header: "Min. Task",
    },
    {
      dataIndex: "taskLimits.max",
      header: "Max. Task",
    },
    {
      dataIndex: "price",
      header: "Price",
    },
    {
      dataIndex: "proRataCost",
      header: "Pro Rata Cost",
    },
    {
      dataIndex: "displayOrderIndex",
      header: "Display Order Index",
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
  ];
  return (
    <div>
      <BaseGrid
        gridUrl="/api/billingplans/list"
        method="POST"
        columns={columns}
      />
      {!_.isEmpty(editModalData) && (
        <div>
          <div>
            <h1>Edit Billing plans</h1>
          </div>
          <div>
            <form>
              <div>
                <label>Price</label>
                <input className="mx-5" value={editModalData!.price}></input>
              </div>
              <div>
                <label>Is Active</label>
                <input className="mx-4" value={editModalData!.isActive}></input>
              </div>
              <div>
                <label>Features</label>
                <input className="mx-4" value={editModalData!.features}></input>
              </div>
            </form>
          </div>
          <div>
            <button onClick={onClose}>Close</button>
            <button onClick={onSave}>Save changes</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingPlanList;
