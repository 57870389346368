import classNames from "classnames";

const theme = {
  base: "flex h-fit items-center justify-center gap-1 rounded-xl",
  color: {
    info: "bg-blue-100 text-blue-800 rounded dark:bg-blue-200 dark:text-blue-800 group-hover:bg-blue-200 dark:group-hover:bg-blue-300",
    gray: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600",
    failure:
      "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
    success:
      "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
    warning:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
    indigo:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 group-hover:bg-indigo-200 dark:group-hover:bg-indigo-300",
    purple:
      "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300",
    pink: "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 group-hover:bg-pink-200 dark:group-hover:bg-pink-300",
  },
  href: "group",
  icon: {
    off: "rounded px-2 py-0.5",
    on: "rounded-full p-1.5",
    size: {
      xs: "w-3 h-3",
      sm: "w-3.5 h-3.5",
    },
  },
  size: {
    xs: "p-1 text-xs",
    sm: "p-1.5 text-sm",
  },
};

export default function WbBadge(props) {
  const {
    children,
    color = "info",
    href,
    icon,
    size = "sm",
    className,
    ...restProps
  } = props;

  const Content = () => (
    <span
      className={classNames(
        theme.base,
        theme.color[color],
        theme.size[size],
        className
      )}
      data-testid="flowbite-badge"
      {...restProps}
    >
      {children && <span>{children}</span>}
    </span>
  );

  return href ? (
    <a className={theme.href} href={href}>
      <Content />
    </a>
  ) : (
    <Content />
  );
}
