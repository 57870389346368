import { Route, Routes } from "react-router-dom";

import SettingsLandingPage from "./SettingsLandingPage";
import EmailLogs from "./adminLogs/EmailLogs";
import AutomationList from "./automationProviders/AutomationList";
import Billingplans from "./billingPlans/BillingPlanList";
import UsersList from "./users/UsersList";
import UsageRecords from "./usagerecords/UsageRecords";
import IntegrationRequestList from "./integrationRequest/IntegrationRequestList";
import WebhookDataList from "./webhookData/WebhookDataList";
import EmailTemplates from "./emailTemplates/EmailTemplatesList";
import EnquiryList from "./enquiry/EnquiryList";
import CSEnquiryList from "./csEnquiry/CSEnquiryList";
import UserSummary from "./analytics/UserSummary";
import FlowSummary from "./analytics/FlowSummary";
import FlowTemplateDetails from "modules/integrations/detailsTab/DetailsTab";
import ConnectAppsTemplate from "./connectApps/SocialAccountList";
import CrmLeadsDataList from "./crmLeads/CrmLeadsDataList";

function SettingsRouter() {
    return (
        <Routes>
            <Route index element={<SettingsLandingPage />} />
            <Route path="email-logs" element={<EmailLogs />} />
            <Route path="automation-providers" element={<AutomationList />} />
            <Route path="billing-plans" element={<Billingplans />} />
            <Route path="users" element={<UsersList />} />
            <Route path="usage-records" element={<UsageRecords />} />
            <Route path="integration-request" element={<IntegrationRequestList />} />
            <Route path="webhook-data" element={<WebhookDataList />} />
            <Route path="email-templates" element={<EmailTemplates />} />
            <Route path="wb-enquiry" element={<EnquiryList />} />
            <Route path="cs-enquiry" element={<CSEnquiryList />} />
            <Route path="user-summary" element={<UserSummary />} />
            <Route path="flow-summary" element={<FlowSummary />} />
            <Route path="flow-templates" element={<FlowTemplateDetails />} />
            <Route path="social-accounts" element={<ConnectAppsTemplate />} />
            <Route path="crm-leads-data" element={<CrmLeadsDataList />} />

        </Routes>
    );
}

export default SettingsRouter;
