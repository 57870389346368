import moment from "moment";

import BaseGrid from "ui/BaseGrid";

const formatter = () => {
  return {
    dateFormatter: (cell: any | undefined) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },
    badgeFormatter: (cell: any) => {
      return <div>{cell.getValue()}</div>;
    },
  };
};

const getColumns = (columnFormatters: {
  dateFormatter: (cell: any) => string | undefined;
  badgeFormatter: (cell: String) => JSX.Element;
}) => {
  return [
    {
      dataIndex: "createdAt",
      header: "Sent On",
      formatter: columnFormatters.dateFormatter,
    },
    {
      dataIndex: "templateName",
      header: "Template Name",
    },
    {
      dataIndex: "name",
      header: "Customer Name",
    },
    {
      dataIndex: "email",
      header: "Customer Email",
    },
    {
      dataIndex: "emailCount",
      header: "Email Count",
    },
    {
      dataIndex: "sentBy",
      header: "Sent By",
      formatter: columnFormatters.badgeFormatter,
    },
    {
      dataIndex: "updatedAt",
      header: "Resent On",
      formatter: columnFormatters.dateFormatter,
    },
  ];
};

export default function EmailLogs() {
  const columnFormatters = formatter();

  const columns = getColumns(columnFormatters);

  return (
    <>
      <BaseGrid gridUrl="/api/logs/list" method="POST" columns={columns} />
    </>
  );
}
