import React from "react";

//Dashboard//
const Dashboard = React.lazy(() => import("./modules/dashboard/Dashboard"));
//User
const UsersList = React.lazy(
	() => import("./modules/settings/users/UsersList")
);
//Integrations//
const IntegrationsList = React.lazy(
	() => import("./modules/integrations/IntegrationsList")
);
const CreateIntegration = React.lazy(
	() => import("./modules/integrations/CreateIntegration")
);
const integrationsDetails = React.lazy(
	() => import("./modules/integrations/Details")
);
const flowFemplates = React.lazy(
	() => import("./modules/integrations/Details")
);
const IntegrationsCard = React.lazy(
	() => import("./modules/integrations/detailsTab/IntegrationsCard")
);
const customersDetails = React.lazy(
    () => import("./modules/integrations/Details")
);
//Integration Request//
const IntegrationRequest = React.lazy(
	() => import("./modules/settings/integrationRequest/IntegrationRequestList")
);
//Customers//
const subscribersDetails = React.lazy(
	() => import("./modules/customers/SubscribersDetails")
);
const CustomersList = React.lazy(
	() => import("./modules/customers/CustomersList")
);

//Flow Progress Data//
const FlowProgressData = React.lazy(() => import("./modules/tasks/TaskList"));
const FlowDetails = React.lazy(() => import("./modules/tasks/TaskDetails"));
//Billing Plans//
const Billingplans = React.lazy(
	() => import("./modules/settings/billingPlans/BillingPlanList")
);
const BillingplanDetails = React.lazy(
	() => import("./modules/settings/billingPlans/BillingPlanDetails")
);
//Webhook Data//
const WebhhokData = React.lazy(
	() => import("./modules/settings/webhookData/WebhookDataList")
);
//Email Templates//
const EmailTemplates = React.lazy(
	() => import("./modules/settings/emailTemplates/EmailTemplatesList")
);
//Logs//
const EmailLogs = React.lazy(
	() => import("./modules/settings/adminLogs/EmailLogs")
);
//Settings//
const SettingsLandingPage = React.lazy(
	() => import("./modules/settings/SettingsLandingPage")
);
//Usage Records//
const UsageRecords = React.lazy(
	() => import("./modules/settings/usagerecords/UsageRecords")
);
//Enquiry//
const EnquiryList = React.lazy(
	() => import("./modules/settings/enquiry/EnquiryList")
);
//CS-Enquiry//
const CSEnquiryList = React.lazy(
	() => import("./modules/settings/csEnquiry/CSEnquiryList")
);
//Automations//
const AutomationList = React.lazy(
	() => import("./modules/settings/automationProviders/AutomationList")
);
const AutomationDetails = React.lazy(
	() => import("./modules/settings/automationProviders/Details")
);
//UserSummary//
const UserSummary = React.lazy(
	() => import("./modules/settings/analytics/UserSummary")
);
const UserDetails = React.lazy(
	() => import("./modules/settings/analytics/UserSummaryDetails")
);
//Flow-Analytics
const FlowSummary = React.lazy(
	() => import("./modules/settings/analytics/FlowSummary")
);
//Flow-Tempalte
const FlowTemplate = React.lazy(
	() => import("./modules/settings/flowtemplate/FlowTemplateList")
);
const Details = React.lazy(
	() => import("./modules/settings/flowtemplate/Details")
);

//CRM Leads Data
const SocialAccountsList=React.lazy(() => import("./modules/settings/connectApps/SocialAccountList"));

const routes = [
    //Home Page path//
    // { path: '/', exact: true, name: 'Home' },
    //Dashboard path//
    { path: "/dashboard", name: "Dashboard", element: Dashboard },
    //User
    { path: "/users", name: "Users", element: UsersList },
    //Integrations path//
    { path: "/integrations", name: "Integrations", element: IntegrationsList },
    {
        path: "/create-integration",
        name: "CreateIntegration",
        element: CreateIntegration,
    },
    {
        path: "/details/:slug",
        name: "integrationsDetails",
        element: integrationsDetails,
    },
    {
        path: "/flowTemplateDetails/slug",
        name: "flowTemplateDetails",
        element: flowFemplates,
    },
	{
        path: "/integrations/customers/:slug",
        name: "customersDetails",
        element: customersDetails,
    },
    { path: "/integrationcard", name: "Users", element: IntegrationsCard },
    //Integration Request path//
    {
        path: "/integrationrequest",
        name: "Integration Request",
        element: IntegrationRequest,
    },
    //Customers path//
    {
        path: "/subscribersDetails/:email",
        name: "Customers",
        element: subscribersDetails,
    },
    { path: "/customers", name: "Customers", element: CustomersList },
    //Flow Progress Data path//
    {
        path: "/tasks",
        name: "Tasks",
        element: FlowProgressData,
    },
    {
        path: "/FlowProgressDetails/:flowId",
        name: "Flow Progress Details",
        element: FlowDetails,
    },
    //Billing Plans path//
    { path: "/billingPlans", name: "Billing plans", element: Billingplans },
    {
        path: "/BillingPlanDetails/:vendorPlanId",
        name: "Billing plans Details",
        element: BillingplanDetails,
    },
    //Webhook Data path//
    { path: "/webhookdata", name: "WebhookData", element: WebhhokData },
    //Email Templates path//
    {
        path: "/email-templates",
        name: "Email Templates",
        element: EmailTemplates,
    },
    //Logs path//
    { path: "/logs", name: "Email Logs", element: EmailLogs },
    //Settings path//
    { path: "/settings", name: "Settings", element: SettingsLandingPage },
    //Usage Records path//
    { path: "/usagerecords", name: "usageRecords", element: UsageRecords },
    //Enquiry path//
    { path: "/enquiry", name: "Enquiry", element: EnquiryList },
    //CSEnquiry path//
    { path: "/cs-enquiry", name: "CS Enquiry", element: CSEnquiryList },
    //Automation//
    { path: "/automations", name: "Automations", element: AutomationList },
    {
        path: "/automation-details/:name",
        name: "automationDetails",
        element: AutomationDetails,
    },
    //UserAnalytics
    { path: "/userSummary", name: "User Summary", element: UserSummary },
    {
        path: "/UserSummaryDetails/:email",
        name: "User Details",
        element: UserDetails,
    },
    //flowAnalytics
    { path: "/flowsummary", name: "Flow Summary", element: FlowSummary },
    { path: "/flowtemplate", name: "Flow Template", element: FlowTemplate },
    { path: "/flowdetails/:name", name: "FlowTemplateList", element: Details },
    
    {path:"/social-accounts",name: "Social Accounts", element:SocialAccountsList}
];

export default routes;
