import { get, startCase } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

import BaseGrid from "ui/BaseGrid";
import WbBadge from "ui/WbBadge";

function FlowsList() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MMM-YYYY hh:mm:ss A");
    },
    statusFormatter: (cell: any) => {
      if (cell.getValue() === "running") {
        return <WbBadge color="success">Running</WbBadge>;
      } else if (cell.getValue() === "deleted") {
        return <WbBadge color="failure">Deleted</WbBadge>;
      } else if (cell.getValue() === "pause") {
        return <WbBadge color="warning">Paused</WbBadge>;
      }
    },
    flowIdFormatter: (cell: any) => {
      const flowId = cell.getValue();
      return (
        <Link to={`/FlowProgressDetails/${flowId}`} className="text-orange-400 underline">
          {flowId}
        </Link>
      );
    },
    subIdFormatter: (cell: any) => {
      const subscriptionId = cell.getValue();
      return (
        <Link to={`/SubscribersDetails/${subscriptionId}`} className="text-orange-400 underline">
          {subscriptionId}
        </Link>
      );
    },
    flowFormatter: (data: any) => {
      const cell = data.getValue() || "";
      const formattedValue = cell.split("_to_") || [];

      return cell ? (
        <div>
          <span>{startCase(formattedValue[0])}</span>
          <span className="m-2 text-orange-400">{"->"}</span>
          <span>{startCase(formattedValue[1])}</span>
        </div>
      ) : (
        <></>
      );
    },
    nameFormatter: (data: any) => {
      const value = data.getValue();
      const flowId = get(data, "row.original._id", "");

      return (
        <Link className=" underline text-orange-500" to={`details/${value}/${flowId}`}>
          {value}
        </Link>
      );
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "name",
      header: "Name",
      isQuickFilter: true,
      formatter: columnFormatter.nameFormatter,
    },
    {
      dataIndex: "state",
      header: "State",
      formatter: columnFormatter.statusFormatter,
      isQuickFilter: true,
    },
    {
      dataIndex: "flowFormattedName",
      header: "From -> To",
      isQuickFilter: true,
      size: 300,
      formatter: columnFormatter.flowFormatter,
    },
    {
      dataIndex: "customerEmail",
      header: "Email",
      formatter: columnFormatter.subIdFormatter,
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
  ];

  return (
    <>
      <BaseGrid gridUrl="/api/flows/list" method="POST" columns={columns} />
    </>
  );
}

export default FlowsList;
