import BaseGrid from "ui/BaseGrid";

const columns = [
  {
    dataIndex: "requestedApps",
    header: "Requested Apps",
    searchable: true,
  },
  {
    dataIndex: "count",
    header: "Total Count",
    searchable: true,
  },
];

function IntegrationRequestList() {
  return (
    <>
      <BaseGrid
        gridUrl="/api/integrationRequest/integration-request-summary"
        method="POST"
        columns={columns}
      />
    </>
  );
}

export default IntegrationRequestList;
