import moment from "moment";

import CreateUser from "./CreateUser";
import BaseGrid from "ui/BaseGrid";

function UsersList() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "_id",
      header: "Id",
      size: "1000px",
    },
    {
      dataIndex: "firstName",
      header: "First Name",
      isQuickFilter: true,
    },
    {
      dataIndex: "lastName",
      header: "Last Name",
      isQuickFilter: true,
    },
    {
      dataIndex: "email",
      header: "Email",
      isQuickFilter: true,
    },
    {
      dataIndex: "permissions",
      header: "Permissions",
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "createdBy",
      header: "Created By",
    },
    {
      dataIndex: "updatedBy",
      header: "Updated By",
    },
  ];

  return (
    <div>
      {/*<CreateUser />*/}
      <BaseGrid
        gridUrl="/api/users/list"
        model="User"
        method="POST"
        columns={columns}
      />
    </div>
  );
}

export default UsersList;
