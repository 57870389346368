import moment from "moment";
import { Link } from "react-router-dom";

import BaseGrid from "ui/BaseGrid";

const columns = [
  {
    dataIndex: "date",
    header: "Date",
    formatter: (cell: Date) => {
      return moment(cell).format("DD-MM-YYYY hh:mm:ss A");
    },
  },
  {
    dataIndex: "_id",
    header: "id",
  },
  {
    dataIndex: "subscriptionId",
    header: "Subscription Id",
    formatter: (cell: any, row: any) => {
      const subscriptionId = cell.getValue();
      return (
        <Link to={`/SubscribersDetails/${subscriptionId}`}>
          {subscriptionId}
        </Link>
      );
    },
  },
  {
    dataIndex: "usageValue",
    header: "Usage Value",
  },
];
export default function UsageRecords() {
  return (
    <>
      <BaseGrid
        gridUrl="/api/usagerecords/list"
        method="POST"
        columns={columns}
      />
    </>
  );
}
