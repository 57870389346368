import { useState } from "react";

interface Props {
  show: boolean;
  stateChange: React.Dispatch<React.SetStateAction<boolean>>;
  header: string | JSX.Element;
  body: string | JSX.Element;
  footer: string | JSX.Element;
}

const ModalComponent = (props: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => (props.show = false);

  return (
    <>
      <div>
        <div>{props.header}</div>
      </div>
      <div>{props.body}</div>
      <div>{props.footer}</div>
    </>
  );
};

export default ModalComponent;
