import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import routes from "../routes";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "ui/NotFound";

import FlowsRouter from "modules/flows/Router";
import SettingsRouter from "modules/settings/SettingsRouter";
import Dashboard from "modules/dashboard/Dashboard";

export default function AuthLayout() {
  const { user } = useAuth0();

  return (
    <>
      <SideBar />
      <main className="flex min-h-screen w-[100%] flex-1 flex-col">
        <TopNavBar />
        <div className="flex-1 bg-gray-50 py-6 px-6">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route element={<ProtectedRoute isAllowed={!!user} />}>
                {routes.map((route, idx) => {
                  return route.element && <Route key={idx} path={route.path} element={<route.element />} />;
                })}
                <Route path="flows/*" element={<FlowsRouter />} />
                <Route path="settings/*" element={<SettingsRouter />} />
              </Route>
              <Route path="" element={<Dashboard />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </main>
    </>
  );
}
