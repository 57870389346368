import { get, isEmpty } from "lodash";
import { DetailCard } from "./DetailCard";

const DetailsTab = (props: any) => {
  
  const { data } = props;
  console.log("data", data)
  const docs = get(data, "docs", []);

  return (
    <>
      <div className="container">
        <div className="g-4">
          {!isEmpty(docs) &&
            docs.map((data: any, index: any) => (
              <DetailCard data={data} index={index} />
            ))}
        </div>
      </div>
    </>
  );
};

export default DetailsTab;
