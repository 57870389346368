import axios from "axios";

const callApi = async (request) => {
  const { url, method, headers, body } = request;
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
      method: method,
      headers: headers || {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      data: body || {},
    });
    return response;
  } catch (error) {
    return error;
  }
};

export default callApi;
