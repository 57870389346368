import moment from "moment";
import { find, get, groupBy, isEmpty, startCase } from "lodash";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import callApi from "lib/callAPi";
import modifiedUrl from "./helper";
const FilterStageInfo = ({ filterStages }: any) => {
    return (
        <>
            <div className="my-4">Tasks will get exectued when the following conditions are met</div>
            {filterStages?.map((stages: any) => {
                const filtersArr = get(stages, "stageItems.setupFilter.selectedFilterForUi", []);
                return filtersArr?.map((stage: any, idx: number) => {
                    const firtParam = get(stage, "ifValue.jsonDetails.0.valueDetails.0.value", "");
                    const condType = get(stage, "operatorValue.jsonDetails.0.valueDetails.0.value", "");
                    const secondParam = get(stage, "secondOperandValue.jsonDetails.0.valueDetails.0.value", "");
                    return (
                        <div key={idx} className="flex flex-col-1 float-left items-center mb-1 justify-center">
                            <span className="font-semibold"> {idx + 1}. If </span>
                            <span className="mx-2 border-2 dark:border-slate-600 rounded-md bg-slate-100 px-1 dark:bg-slate-600">
                                {firtParam}
                            </span>
                            <span className="mx-2 border-2 dark:border-slate-600 rounded-md bg-slate-100 px-1 dark:bg-slate-600">
                                {condType}
                            </span>
                            <span className="mx-2 border-2 dark:border-slate-600 rounded-md bg-slate-100 px-1 dark:bg-slate-600">
                                {secondParam}
                            </span>
                        </div>
                    );
                });
            })}
        </>
    );
};
function Details(props: any) {
    const [taskDetails, setTaskDetails] = useState<any>({});
    const { flowName, flowId } = useParams();
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const fetchDetails = async () => {
        setLoading(true);
        const response: any = await callApi({
            url: `/api/flows/getDetails?flowId=${flowId}`,
            method: "get",
        });
        setLoading(false);
        setData(response.data);
    };
    useEffect(() => {
        fetchDetails();
        getFlowDetails();
    }, []);
    const flowData = data.data;
    const subDetails = data.subDetails;
    const name = get(flowData, "name", "");
    const state = get(flowData, "state", "");
    const createdAt = get(flowData, "createdAt", "");
    const updatedAt = get(flowData, "updatedAt", "");
    const groupByStages = groupBy(flowData?.stages, "stageType");
    const getFlowDetails = async () => {
        const url = `/api/flowprogressdata/${flowId}`;
        const method = "get";
        const body = {
            flowId,
        };
        const callObject = { url, method, body };
        try {
            const response: any = await callApi(callObject);
            setTaskDetails(response.data);
        } catch (error) {
            throw new Error(`Failed to fetch chart data`);
        }
    };
    //Trigger Related Data
    const tSlug: any = get(groupByStages, "trigger.0.stageItems.selectIntegration.selectedIntegrationSlug", "");
    const tUrl = modifiedUrl(tSlug);
    const eventSlug: any = get(groupByStages, "trigger.0.stageItems.selectEvent.selectedEventSlug", "");
    const tAccId = get(groupByStages, "trigger.0.stageItems.selectAccount.selectedAccountId", "");
    const dynamicData = get(
        groupByStages,
        "trigger.0.stageItems.setupEventOptions.selectedDynamicIncomingDataPrerequisites",
        []
    );
    let connectedTriggerAcc: any;
    connectedTriggerAcc = find(subDetails?.accounts, { _id: tAccId });
    //Filter Stage Data
    const filterStage = get(groupByStages, "filter", []);
    //Action Related Data
    const aSlug: any = get(groupByStages, `action.0.stageItems.selectIntegration.selectedIntegrationSlug`, "");
    const actionSlug: any = get(groupByStages, `action.0.stageItems.selectAction.selectedActionSlug`, "");
    const aAccId = get(groupByStages, `action.0.stageItems.selectAccount.selectedAccountId`, "");
    const connectedActionAcc = find(subDetails?.accounts, { _id: aAccId });
    return (
        <div className="m-4 font-sans p-4">
            <div className="flex flex-col">
                <div className="text-gray-800 font-bold capitalize mt-4 flex justify-between">
                    <div>
                        <span className="text-lg">
                            <div>
                                Flow Name : <span className="font-medium">{name}</span>
                            </div>
                            Status:{" "}
                            <span
                                className={
                                    state === "running"
                                        ? "text-green-600"
                                        : state === "paused"
                                        ? "text-yellow-500"
                                        : "text-red-500"
                                }
                            >
                                {state}{" "}
                            </span>
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-lg">
                            Created At :<span className="font-medium pl-1">{moment(createdAt).format("lll")}</span>
                        </div>
                        <div className="text-lg">
                            Updated At :<span className="font-medium pl-1">{moment(updatedAt).format("lll")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 pl-12 text-lg font-semibold text-center">
                When {startCase(eventSlug)} event received do {startCase(actionSlug)}
            </div>
            <div className="mt-6 grid grid-cols-2 gap-6 px-10">
                <div className="shadow-lg rounded border border-gray-300  p-4 mt-2">
                    <div className="font-bold text-lg text-center">Trigger App ({startCase(tSlug)})</div>
                    <div className="flex flex-col p-5">
                        <div className="font-semibold float-left">
                            Connected Account:{" "}
                            <span className="font-normal">
                                {!isEmpty(connectedTriggerAcc) ? connectedTriggerAcc?.name : "No Accounts Found"}
                            </span>
                        </div>
                        {dynamicData?.map((eachData: any, index: any) => {
                            const displayText = get(eachData, "valueDetails.displayText", "");
                            const slug = get(eachData, "slug", "");
                            return (
                                <div className="" key={index}>
                                    <span className="font-semibold">
                                        {startCase(slug)}: <span className="font-normal">{displayText}</span>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="shadow-lg rounded border border-gray-300 text-center p-4 mt-2">
                    <div className="font-bold text-lg">Action App ({startCase(aSlug)})</div>
                    <div className="p-5 float-left">
                        <div className="font-semibold">
                            Connected Account:{" "}
                            <span className="font-normal">
                                {!isEmpty(connectedActionAcc) ? connectedActionAcc?.name : "No Accounts Found"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6">
                <>
                    <div className="flex justify-center">
                        {!isEmpty(filterStage) && (
                            <div className="shadow-lg rounded border border-gray-300 text-center p-4 mt-2 w-[50%]">
                                <h1 className="font-bold text-lg">Filter Details</h1>
                                <FilterStageInfo filterStages={filterStage} />
                            </div>
                        )}
                    </div>
                </>
            </div>
        </div>
    );
}
export default Details;
