import { useEffect, useRef, useState } from "react";
import { get, kebabCase } from "lodash";
import { Chart } from "react-chartjs-2";
import colorLib from "@kurkle/color";
import "chart.js/auto";

import callApi from "lib/callAPi";

interface IUserSummaryState {
  plotLabels: string;
  plotData: number;
  showFields: boolean;
}

const UserSummary = () => {
  const chartRef = useRef(null);
  const [plotData, setPlotData] = useState<IUserSummaryState[]>();
  const [plotLabels, setPlotLabels] = useState<IUserSummaryState[]>();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const [filterType, setFilterType] = useState("Subscribers Count");
  const [showDataPointsField, setShowDataPointsField] = useState(false);
  const [dataPointItems, setDataPointItems] = useState(["Flows Count"]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [startDate, endDate, filterType]);

  const getUsers = async () => {
    const url = `/api/dashboard/summary/userSummary`;
    const method = "POST";
    const body = {
      filter: { startDate, endDate },
      flag: kebabCase(filterType),
    };
    const callObject = { url, method, body };
    try {
      const response = await callApi(callObject);
      handleChartData(get(response, "data", ""));
    } catch (error) {
      throw new Error(`Failed to fetch chart data`);
    }
  };

  function handleChartData(data: any) {
    let subscriberData: any[] = [];
    let subscriberLabels: any[] = [];

    data.map(
      (item: { count: number; timeline: string }) => (
        subscriberData.push(get(item, "count", "")),
        subscriberLabels.push(get(item, "timeline", ""))
      )
    );
    setPlotData(subscriberData);
    setPlotLabels(subscriberLabels);
  }

  const data = {
    labels: plotLabels,
    datasets: [
      {
        label: filterType.split(" ")[0],
        backgroundColor: transparentize("rgb(54, 162, 235)", "0.5"),
        borderColor: "rgb(54, 162, 235)",
        pointStyle: "circle",
        pointRadius: 8,
        pointHoverRadius: 15,
        fill: true,
        data: plotData,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    delayed: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `${filterType.split(" ")[0]} total count`,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: filterType == "Subscribers Count" ? "Month" : "Users",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: filterType == "Subscribers Count" ? "Count" : "Flow Count",
        },
      },
    },
  };

  const filterItems = ["Date Range", "Data Points"];

  const handleFilterType = (itemName: any) => {
    if (itemName == "Date Range") {
      setShowDataPointsField(false);
      setShowDateRangePicker((prev) => !prev);
    } else if (itemName == "Data Points") {
      setShowDateRangePicker(false);
      setShowDataPointsField((prev) => !prev);
    }
  };

  const selectStartDate = (date: any) => {
    setStartDate(date);
  };

  const selectEndDate = (date: any) => {
    setEndDate(date);
  };

  const handleDataPointType = (dataPoint: any) => {
    setFilterType(dataPoint);
    alterDataPointList(dataPoint);
  };

  const alterDataPointList = (itemName: any) => {
    dataPointItems.splice(dataPointItems.indexOf(itemName), 1, filterType);
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-3">
        <div>
          {/*<Col md={4} className="mt-3">
            <AppDropdown
              name="Filters"
              iconName={""}
              menuItems={filterItems}
              handleClick={handleFilterType}
              helperText="Select Filters"
            />
          </Col>

          {showDateRangePicker && (
            <Col md={8} className="d-flex justify-content-center">
              <div className="d-flex justify-content-lg-between">
                <Col sm={3}>
                  <span>Start Date</span>
                  <ReactDatePicker
                    placeholderText="Select Start Date"
                    selected={startDate}
                    onChange={selectStartDate}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    closeOnScroll={true}
                  />
                </Col>
                <Col sm={3}>
                  <span>End Date</span>
                  <ReactDatePicker
                    placeholderText="Select End Date"
                    selected={endDate}
                    onChange={selectEndDate}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    closeOnScroll={true}
                  />
                </Col>
              </div>
            </Col>
          )}
          {showDataPointsField && (
            <Col md={8} className="d-flex justify-content-center">
              <AppDropdown
                name="Data Points"
                iconName={""}
                menuItems={dataPointItems}
                handleClick={handleDataPointType}
                helperText="Select data points"
              />
            </Col>
          )}*/}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center">
          <Chart
            redraw={true}
            ref={chartRef}
            type={filterType == "Subscribers Count" ? "line" : "bar"}
            height={120}
            data={data}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default UserSummary;

export function transparentize(value: any, opacity: any) {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}
