import { Menu } from "@headlessui/react";
import classNames from "classnames";

const theme = {
  base: "inline-flex w-full items-center justify-center rounded-md",
  colors: {
    primary:
      "bg-orange-800 px-6 py-3 text-white rounded-lg cursor-pointer disabled:opacity-75 hover:bg-orange-700",
  },
};

export default function WbMenuButton(props) {
  const { color, className, ...restProps } = props;

  return (
    <Menu.Button
      className={classNames(theme.base, theme.colors[color], className)}
      {...restProps}
    >
      {props.children}
    </Menu.Button>
  );
}
