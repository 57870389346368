import moment from "moment";

import BaseGrid from "ui/BaseGrid";

function EnquiryList() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "name",
      header: "Name",
      searchable: true,
    },
    {
      dataIndex: "email",
      header: "Email",
      searchable: true,
    },
    {
      dataIndex: "phoneNumber",
      header: "Phone Number",
    },
    {
      dataIndex: "companyName",
      header: "Company Name",
    },
    {
      dataIndex: "enquiryType",
      header: "Enquiry Type",
    },
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
  ];

  return (
    <>
      <BaseGrid
        gridUrl="/api/enquiry/list"
        method="POST"
        columns={columns}
        model="Enquiry"
      />
    </>
  );
}

export default EnquiryList;
