import moment from "moment";
import { Link } from "react-router-dom";

import BaseGrid from "ui/BaseGrid";

function AutomationList() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell.getValue()).format("DD-MM-YYYY hh:mm:ss A");
    },

    nameFormatter: (cell: any, row: any) => {
      const name = cell.getValue();
      return <Link to={`/automation-details/${name}`}>{name}</Link>;
    },

    statusFormatter: (cell: any) => {
      if (cell.getValue() === "approved") {
        return <div>Approved</div>;
      } else if (cell.getValue() === "pending") {
        return <div>Pending</div>;
      }
    },
  };

  const columns = [
    {
      dataIndex: "createdAt",
      header: "Date",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "_id",
      header: "Id",
      size: "1000px",
    },
    {
      dataIndex: "name",
      header: "Name",
      formatter: columnFormatter.nameFormatter,
      isQuickFilter: true,
    },
    {
      dataIndex: "status",
      header: "Status",
      formatter: columnFormatter.statusFormatter,
    },
    {
      dataIndex: "email",
      header: "Email",
      isQuickFilter: true,
    },
    {
      dataIndex: "services",
      header: "Services",
      isQuickFilter: true,
    },
    {
      dataIndex: "url",
      header: "Url",
    },
    /*{
      dataIndex: "description",
      header: "Description",
    },*/
    {
      dataIndex: "updatedAt",
      header: "Updated At",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataIndex: "count",
      header: "Count",
    },
  ];

  return (
    <div>
      {/*<CreateAutomation />*/}
      <BaseGrid
        gridUrl="/api/automationproviders/list"
        method="POST"
        columns={columns}
      />
    </div>
  );
}

export default AutomationList;
