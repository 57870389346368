import { ModalComponent } from "components";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";

export const DetailCard = (props: { data: any; index: number }) => {
  const { data, index } = props;
  const [showModal, setShowModal] = useState(false);
  const slug = get(data, "slug", "");
  const displayText = get(data, "displayText", "");
  let apiKeys = displayText;

  const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
  const randomRGB = `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

  !isEmpty(get(data, "oAuthDetails", {}))
    ? (apiKeys = "OAuth")
    : (apiKeys = "API KEY");

  const uiData: any = [];
  get(data, "apiKeys", []).forEach(({ displayText }: any) =>
    uiData.push({ displayText })
  );

  const handelshow = () => {
    setShowModal(true);
  };

  return (
    <>
      <div>
        <div
          onClick={handelshow}
          style={{ width: "18rem" }}
          className="card h-100 w-100 mt-3 border border-secondary"
        >
          <div style={{ background: randomRGB }}>
            {index + 1}. {displayText}
          </div>
          <div>
            <b>Slug:</b> <Link to={`/Details/${slug}`}>{slug}</Link>
            <div>
              <b>Auth Type:</b> {apiKeys}
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        stateChange={setShowModal}
        header={"AuthData"}
        body={
          <div>
            <>
              {!isEmpty(uiData) ? (
                <div>
                  {uiData?.map(({ displayText }: any) => (
                    <div>{displayText}</div>
                  ))}
                </div>
              ) : (
                <p>No data</p>
              )}
            </>
          </div>
        }
        footer={""}
      />
    </>
  );
};
