import { Link } from "react-router-dom";
import {
  FaCog,
  FaDashcube,
  FaUserAlt,
  FaWater,
  FaTasks,
  FaLink,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";

const menuItems = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: <FaDashcube />,
  },
  {
    title: "Customers",
    href: "/customers",
    icon: <FaUserAlt />,
  },
  {
    title: "Flows",
    href: "/flows",
    icon: <FaWater />,
  },
  {
    title: "Tasks",
    href: "/tasks",
    icon: <FaTasks />,
  },
  {
    title: "Integrations",
    href: "/integrations",
    icon: <FaLink />,
  },
  {
    title: "Settings",
    href: "settings",
    icon: <FaCog />,
  },
];

function SideBar() {
  const { pathname } = useLocation();

  return (
    <aside className="w-62 sticky top-0 border-r border-r-gray-300 bg-slate-600 text-white">
      <div className="flex flex-col space-y-10">
        <div className="border-b border-b-gray-800 bg-gray-700 pb-5">
          <img
            src="/wyzebulb.png"
            className="mx-10 mt-10 h-8 w-40 object-contain"
            alt="Logo"
          />
        </div>
        <ul className="flex h-full flex-col space-y-6 px-10">
          {menuItems.map((menuItem, idx) => (
            <li key={idx} className="group">
              <Link
                aria-current="page"
                to={menuItem.href}
                className={`flex items-center space-x-2 group-hover:text-orange-500 ${
                  pathname.includes(menuItem.href) ? "text-orange-500" : ""
                }`}
              >
                <span className="absolute left-0 h-8 w-2 scale-y-0 rounded bg-orange-500 transition-transform duration-300 ease-in-out group-hover:scale-y-100"></span>
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

export default SideBar;
