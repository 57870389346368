import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import AuthLayout from "layout/AuthLayout";
import UnAuthLayout from "layout/UnAuthLayout";

function App() {
  const { isLoading, error, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const emailDomain = user?.email?.split("@")[1];
  const allowedEmailDomains = ["wyzebulb.com", "codosphere.com"];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("access_token", accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const isAllowed = isAuthenticated && allowedEmailDomains.includes(emailDomain);

  return (
    <div className="flex h-full bg-gray-50">
      {/*<RouterProvider router={router} />*/}
      {isAllowed ? <AuthLayout /> : <UnAuthLayout notAllowed={isAuthenticated && !isAllowed} />}
    </div>
  );
}

export default App;
