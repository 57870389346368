import moment from "moment";

function FlowSummary() {
  const columnFormatter = {
    dateFormatter: (cell: any) => {
      return moment(cell).format("DD-MM-YYYY hh:mm:ss A");
    },
  };

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: columnFormatter.dateFormatter,
    },
    {
      dataField: "flowFormattedName",
      text: "Flow Name",
      isQuickFilter: true,
    },
    {
      dataField: "count",
      text: "Flow Count",
      isQuickFilter: true,
    },
    {
      dataField: "taskExecuted",
      text: "Total Task Executed",
      isQuickFilter: true,
    },
  ];

  return (
    <>
      {/*<WbGrid url="/api/analytics/flowList" method="POST" columns={columns}>
      Flows List
  </WbGrid>*/}
    </>
  );
}

export default FlowSummary;
